@import './mixins/mixins';

@font-face {
  font-family: 'digit';
  src: url('../../../public/fonts/digital-7.ttf') format('truetype');
}
// GENERALS
* {
  scrollbar-width: thin;
  scrollbar-color: var(--backgroundColorDarker20) transparent;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    display: inline-block;
    background: #bfbfbf;
    border-radius: 20px;
  }
}

body {
  height: 100vh;
  height: fill-available;
  height: -moz-available;
  height: -webkit-fill-available;
  width: 100vw;
  overflow: hidden;
  background: var(--backgroundColor);
}
#root {
  height: 100%;
  width: 100%;
}
.ant-layout {
  background: none;
}
.main-layout {
  height: 100%;
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  .main-content-layout {
    height: 100%;
    display: flex;
    overflow-y: hidden;
    & > div {
      width: 100%;
      flex: 1;
      display: flex;
      overflow-y: hidden;
    }
    @media (max-width: 992px) {
      .gutter {
        display: none;
      }
    }
  }
}

@media (max-width: 768px) {
  button:not(.ant-btn-icon-only, .ant-btn-sm, .user-avatar, .navbar-burger-sider, .help-button),
  input,
  textarea,
  .ant-select {
    font-size: 16px !important;
  }
  .ant-row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}
@media (max-width: 576px) {
  button:not(
      .ant-btn-sm,
      .ant-btn-icon-only,
      .ant-drawer-close,
      .ant-modal-close,
      .user-avatar,
      .navbar-burger-sider,
      .help-button
    ) {
    width: 100%;
  }
}

.meta-text-fix {
  display: flex;
  align-items: center;
  padding: 10px;
  width: max-content;
}

.language__select {
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
}

.empty-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.customization {
  .ant-form-item {
    margin: 0;
  }
}

// Edit Inline Input
.edit-inline {
  position: relative;
  .ant-form-item-label,
  .ant-form-item-control {
    padding: 0 !important;
    flex: 1;
  }
  label {
    height: 100%;
    &::after {
      content: none;
    }
  }
  .edit-inline-formitem {
    width: 100%;
    margin-right: 0;
    display: flex;
    .ant-form-item-control-input-content {
      input,
      textarea {
        padding-right: 40px;
        padding-left: 8px;
        border: none;
        background-color: rgba($color: lightgrey, $alpha: 0.1);
        background-image: none !important;
        &.ant-input {
          min-height: 32px;
        }
      }
      input {
        text-overflow: ellipsis;
      }
      .ant-input-disabled {
        opacity: 0.6;
      }
    }
  }
  .edit-inline-button {
    position: absolute;
    margin: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: 0.2s 0.2s;
    overflow: hidden;
    border-radius: 4px;
    button {
      cursor: default;
    }
  }
}
.edit-inline.active {
  .edit-inline-button {
    opacity: 1;
    button {
      cursor: pointer;
    }
  }
}

.ant-tabs {
  height: 100%;
  overflow: unset;
  .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      .ant-tabs-tab {
        // opacity: 0.6;
        transition: 0.2s;
        &.ant-tabs-tab-active {
          opacity: 1;
        }
        .ant-tabs-tab-btn {
          font-weight: initial;
        }
      }
    }
    .ant-tabs-nav-more {
      line-height: 1em;
    }
    .ant-tabs-nav-operations {
      .ant-tabs-nav-more {
        margin-right: 0 !important;
      }
    }
  }
}
.ant-tabs-tab,
.ant-tabs-dropdown-menu-item {
  .ant-avatar {
    border-radius: 0;
  }
  .ant-badge {
    color: inherit;
  }
}
.ant-tabs-nav::before {
  border-bottom: none !important;
  margin: 0;
}

.ant-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
}

.sync-handler__container {
  display: flex;
  align-items: center;
}
.sync__input-group::before {
  display: none !important;
}

.sync-handler__interval-popover {
  .ant-form {
    .ant-form-item:last-child {
      margin-bottom: 0;
    }
  }
}

.ant-upload.ant-upload-drag {
  .ant-upload {
    padding: 0;
  }
}

.modal-form {
  .modal-form__button {
    margin-bottom: 0;
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.flex-formitem {
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
  }
}

.ant-modal-title {
  color: var(--textColor);
  .anticon {
    margin-right: 10px;
  }
}

.content {
  position: relative;
}
.center-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.table-filter-hover-icon {
  position: relative;
  font-size: 12px;
  z-index: 1;
  color: #bbb;
  transition: 0.2s;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 46px;
    width: 27.6px;
    background-color: rgba($color: lightgrey, $alpha: 0.5);
    z-index: -1;
    opacity: 0;
    transition: 0.2s;
  }
  &:hover {
    color: #777;
    &::after {
      opacity: 1;
    }
  }
}
.table-filter-menu {
  transform: translate(7px, 17px);
}

.ant-form-item-label {
  overflow: visible;
  label {
    white-space: normal;
    line-height: 0.9em;
  }
}

.language-select {
  min-width: 140px;
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
}

.form-item-horizontal {
  margin: 0 !important;
  flex-direction: row !important;
  .ant-form-item-label label {
    height: 32px;
    margin-right: 10px;
  }
}
.form-item-bordered-group {
  border: solid 1px rgba(200, 200, 200, 0.3);
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
  .ant-form-item:last-of-type {
    margin-bottom: 0;
  }
}

.file-upload {
  &.block {
    display: block;
    width: 100%;
    .ant-upload {
      display: block;
      width: 100%;
    }
  }
}

.ant-tree-show-line .ant-tree-switcher-line-icon {
  vertical-align: -0.125em;
}
.ant-tree-checkbox {
  margin-right: 3px;
}

.connexion-badge-tooltip {
  padding-bottom: 1px;
  padding-left: 3px;
  .ant-tooltip-inner {
    background: none;
    padding: 0;
    box-shadow: none;
    min-height: auto;
  }
  .ant-tooltip-arrow {
    display: none;
  }
  .ant-tag {
    margin: 0;
  }
}
.connexion-badge {
  &.connexion-badge-status-error {
    .ant-badge-status-error {
      border: solid 2px #ff4d4f;
      background: none;
    }
    &.connexion-badge-large .ant-badge-status-dot {
      border: solid 3px #ff4d4f;
    }
  }

  &.connexion-badge-large .ant-badge-status-dot {
    width: 10px;
    height: 10px;
  }
  .ant-badge-status-text {
    display: none;
  }
}

.nb-users {
  background: rgba($color: black, $alpha: 0.05);
}

.add-modal-result {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.skeleton-tree {
  pointer-events: none;

  .ant-tree-node-content-wrapper {
    display: flex;
    align-items: center;
    .ant-skeleton-element {
      display: flex;
    }
    .ant-tree-icon__customize {
      margin-right: 6px;
    }
  }
  .ant-tree-switcher {
    background: none !important;
  }
}

// WARNING: VERY TOUCHY

.ant-space-item {
  display: flex;
}

.badge-big {
  .ant-badge-status-dot {
    width: 10px;
    height: 10px;
  }
  &.status-disconnected {
    .ant-badge-status-error {
      border: solid 3px #ff4d4f;
    }
  }
}

.ant-avatar {
  img {
    object-fit: contain;
  }
}

.form-item-separator {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

// THEMES
.select-color .ant-select-selection-selected-value,
.color-options {
  span {
    &:first-child {
      position: relative;
      $size: 22;
      height: #{$size}px;
      width: #{$size}px;
      display: inline-block;
      vertical-align: middle;
      transform: translateY(-2px);
      margin-right: 10px;
      border-radius: 3px;
      &::after {
        content: '';
        position: absolute;
        height: 5px;
        top: 3px;
        right: 3px;
        left: 3px;
        border-radius: 100px;
        display: inline-block;
      }
    }
  }
}
.theme-changing {
  div,
  p,
  span,
  ul,
  li,
  input,
  form,
  aside,
  table,
  td,
  tr,
  th,
  picture,
  img,
  section,
  header,
  button,
  i {
    transition: none !important;
  }
}

@import 'components';
@import './themes';
@import '~antd/dist/antd.min.css';
