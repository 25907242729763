/****************************** CONNECT ****************************/
/*                                                                 */
/*    This file is for the all the CONNECT pages of the project    */
/*                                                                 */
/*******************************************************************/

.connect {
  position: relative;
  height: 100vh;
  background-color: #fafafa;
  .connect__card {
    z-index: 10;
    background-color: #fafafa;
    position: absolute;
    top: 15vh;
    left: 7vw;
    width: 320px;
    .ant-card {
      background-color: inherit !important;
    }
    .ant-card-body {
      padding: 12px;
    }
    @media (max-width: 992px) {
      left: 100px;
    }
    @media (max-width: 576px) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @media (max-width: 351px) {
      width: 100%;
      left: 0;
      transform: translate(0, -50%);
    }

    .ant-divider-inner-text {
      font-weight: 300;
      font-size: 0.9em;
    }

    .ant-btn,
    .ant-input,
    .ant-input-password {
      border-radius: 8px;
    }
    .ant-input,
    .ant-input-password {
      border-radius: 8px;
      background-color: #f4f5f9;
      color: #343862;
    }
    .ant-input::placeholder {
      color: #c3c8cc;
    }
    .ant-form-item-has-error .ant-input {
      background: #f4f5f9;
    }
    .submit-button {
      background: var(--primaryColor);
      border-color: var(--backgroundColor);
      box-shadow: 0 4px 15px -8px var(--backgroundColor);
      &:focus,
      &:hover {
        background: var(--primaryColorLighter10);
        border-color: var(--backgroundColorLighter10);
      }

      &.blue {
        background: #223ea4;
        border-color: #223ea4;
        box-shadow: 0 4px 15px -8px #223ea4;
        &:focus,
        &:hover {
          background: #4666d9;
          border-color: #4666d9;
        }
      }
    }
  }

  .author-link {
    color: #343862;
    z-index: 10;
    position: absolute;
    bottom: 20px;
    left: calc(7vw + 12px);
    text-align: center;
    @media (max-width: 992px) {
      left: 100px;
    }
    @media (max-width: 576px) {
      left: 50%;
      transform: translateX(-50%);
    }
    @media (max-width: 351px) {
      width: 100%;
    }
    a {
      font-weight: 800;
      text-transform: uppercase;
    }
  }

  .auth-back {
    opacity: 0.7;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 60%;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 150vh;
    @media (max-width: 992px) {
      background-position: right -1200px center;
    }
    @media (max-width: 576px) {
      display: none;
    }
    &.blue {
      background-image: url('/assets/images/authBackBlue.svg');
    }
    &.red {
      background-image: url('/assets/images/authBackBlueDark.svg');
    }
  }

  .auth-machine {
    display: flex;
    width: 650px;
    height: 650px;
    position: absolute;
    top: 45%;
    right: 6vw;
    transform: translateY(-45%) scaleX(-1);
    animation: 4s ease-in-out 1s infinite alternate float;
    svg {
      filter: drop-shadow(-15px 30px 20px rgba(17, 31, 82, 0.2));
      animation: 4s ease-in-out 1s infinite alternate floatShadow;
    }
    @media (max-width: 1400px) {
      width: 500px;
      height: 500px;
    }

    @media (max-width: 992px) {
      display: none;
    }
  }
}

@keyframes float {
  from {
    transform: translateY(-45%) scaleX(-1);
  }
  to {
    transform: translateY(-48%) scaleX(-1);
  }
}
@keyframes floatShadow {
  from {
    filter: drop-shadow(-15px 30px 20px rgba(17, 31, 82, 0.2));
  }
  to {
    filter: drop-shadow(-20px 60px 40px rgba(17, 31, 82, 0.1));
  }
}
