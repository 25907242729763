// /***************************** NAVBAR *****************************/
// /*                                                                */
// /*    This file is for the NAVBAR and all the components in it    */
// /*                                                                */
// /******************************************************************/

.navbar {
  padding: 0 15px;
  display: flex;
  flex: 0 0 64px;
  align-items: center;
  background: none;
  z-index: 1;

  .navbar-left,
  .navbar-right,
  .navbar-middle {
    display: flex;
    align-items: center;
  }

  .navbar-left {
    flex-grow: 1;
    justify-content: flex-start;

    .navbar-burger-sider {
      display: none;
      background: none;
      border: none;
      box-shadow: none;
      &.navbar-burger-hidden {
        display: none;
      }
    }

    .topbar-logo-container {
      display: block;
    }

    @media (max-width: 992px) {
      // dans le cas d'une vue mobile, on affiche le menu burger et on cache le logo
      .topbar-logo-container {
        display: none;
      }
      .navbar-burger-sider {
        display: block;
      }
    }
  }

  .navbar-middle {
    flex-grow: 4;
    justify-content: center;
  }

  .navbar-middle {
    a {
      padding: 7px 15px;
      border-radius: 7px;
      font-size: 18px;
      font-style: normal;
      color: var(--actionColor);
      &.active {
        background-color: var(--primaryColor);
        color: white;
      }
      .anticon {
        padding-right: 10px;
      }
      &:hover .anticon {
        color: var(--antd-wave-shadow-color);
        transition: 0.3s;
      }
      @media (max-width: 992px) {
        span.anticon {
          padding-right: 0px;
        }
        .menu__text {
          display: none;
        }
      }
    }
  }

  .navbar-right {
    flex-grow: 1;
    justify-content: flex-end;

    .user-avatar {
      text-transform: uppercase;
      font-weight: bolder;
      height: 38px;
      width: 38px;
      text-align: center;
      padding: 0;
      font-size: 18px;
      border: none;
      background: linear-gradient(45deg, #fbda61 0%, #ff5acd 100%);
    }

    @media (max-width: 992px) {
      .help-button span:last-child {
        display: none;
      }
      .changelog-button {
        display: none;
      }
    }
    @media (max-width: 480px) {
      .help-button {
        display: none;
      }
    }
  }

  &.navbar-border {
    border-bottom: 1px solid #f1f1f1;
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.01);
  }
}
