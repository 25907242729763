/********************************** SIDER ***********************************/
/*                                                                          */
/*    This file is for the SIDER and all the components and applis in it    */
/*                                                                          */
/****************************************************************************/

.sider {
  flex: initial !important;
  transition: 0s !important;
  background: none;

  // @media (max-width: 1200px) {
  //   min-width: 300px;
  // }

  @media (max-width: 992px) {
    &:not(.sider-hidden) {
      display: block;
      .finder,
      .ant-tabs {
        // le finder prend toute la largeur de l'écran lorsqu'il est ouvert en mode mobile
        width: 100vw;
      }
    }
    &.sider-hidden {
      display: none;
    }
  }

  @media (min-width: 992px) {
    display: block;
  }

  // .ant-tabs {
  //   overflow: auto;
  // }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  .ant-layout-sider-zero-width-trigger {
    z-index: 1000;
    background: none !important;
    color: #343862;
  }

  .finder {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    overflow: hidden;
    $sider-padding: 20px;

    // FINDER SEARCH
    .finder__search {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: $sider-padding;
      .ant-select {
        width: 100%;
        .ant-select-selector {
          border: none;
        }
      }
      .ant-input-wrapper {
        border: none;
        .ant-input {
          width: 100%;
        }
      }
      .finder__search__button {
        width: max-content;
        padding: 4px;
      }
      .finder__search__settings {
        margin-bottom: 10px;
      }
    }

    // FINDER TREE OR LIST
    .finder__content {
      width: 100%;
      flex: 1;
      overflow: auto;
      .finder__object-tree {
        padding: 10px;
        .ant-tree {
          background: none;
          .ant-tree-node-content-wrapper {
            white-space: nowrap;
            .sider__tree-node-icon {
              width: 100%;
              height: 100%;
              margin-top: -4px;
              margin-left: -4px;
              img {
                object-fit: contain;
                padding: 3px;
              }
            }
          }
        }
      }
    }

    .finder__footer {
      .sider__add__button {
        margin: 20px 0;
      }
    }
  }
}

.gutter {
  position: relative;
  min-width: 10px !important;
  cursor: ew-resize;
  transition: 0.3s;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
    width: 4px;
    height: 20px;
    border-radius: 100px;
    background-color: rgba($color: grey, $alpha: 0.4);
  }
  &:hover {
    background-color: rgba($color: grey, $alpha: 0.1);
  }
}

@import 'nodesSider';
@import 'dashboardsSider';
@import 'managementSider';
@import 'rightClick';
