.changelog-modal {
  .title {
    color: #343862;
    margin-bottom: 0;
    line-height: 1em;
  }
  .ant-btn:not(.ant-btn-circle) {
    border-radius: 8px;
  }
  .slide-navigation {
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 100;
    .ant-btn {
      background: #f1f5f9;
    }
  }
  .ok-button {
    position: absolute;
    bottom: 30px;
    right: 40px;
  }
  .ant-list {
    .ant-list-items {
      & > .ant-space {
        display: flex;
        padding: 2px 0;
      }
    }
  }
  .welcome-slide {
    .paragraph {
      font-size: 18px;
      line-height: 1.2em;
      color: #343862;
    }
  }
  .interface-slide {
    .title,
    .list-type {
      color: #ee4e96;
    }
    .ant-avatar {
      background-color: lighten($color: #ee4e96, $amount: 35%);
    }
  }
  .structure-slide {
    .title,
    .list-type {
      color: #1fd3ab;
    }
    .ant-avatar {
      background-color: lighten($color: #1fd3ab, $amount: 48%);
    }
  }
  .features-slide {
    .title,
    .list-type {
      color: #d3691f;
    }
    .ant-avatar {
      background-color: lighten($color: #d3691f, $amount: 48%);
    }
  }
}
