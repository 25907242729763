//*********  LIGHT  *********//

.light {
  * {
    scrollbar-color: rgba($color: black, $alpha: 0.3) rgba($color: black, $alpha: 0.1);
    &::-webkit-scrollbar-thumb {
      background: rgba($color: black, $alpha: 0.3);
    }
  }

  // DASHBOARDS - A REVOIR AVEC DASHBOARDS
  .content-layout {
    .react-grid-item {
      .tile {
        background: rgba($color: black, $alpha: 0.05);
        &.sync-tile {
          border-color: rgba($color: $primary, $alpha: 0.5);
        }
      }
    }
  }
  .serie-collapse {
    background: none !important;
    .serie-list-panel {
      background: white;
    }
  }

  .react-grid-layout {
    .react-grid-item {
      .tile-edit-icons {
        background: rgba($color: black, $alpha: 0.2);
        border: dotted 2px rgba($color: black, $alpha: 0.5);
        i {
          background: rgba($color: white, $alpha: 0.8);
        }
      }
      .react-resizable-handle {
        &:after {
          // Handler resize
          border-color: rgba($color: black, $alpha: 0.5);
        }
      }
    }
  }

  .sider-dashboard-settings {
    box-shadow: 0 0 20px rgba($color: black, $alpha: 0.1);
  }

  .management-content {
    background: white;
  }

  //Root Menu
  .settings-root-menu {
    .ant-menu-item {
      &:hover {
        background: #a5ddff;
        color: black !important;
      }
    }
    .ant-menu-item-selected {
      background: none !important;
    }
  }

  .permission-modal {
    .ant-modal-body {
      .ant-tabs-bar {
        box-shadow: 0 10px 25px -20px rgba($color: black, $alpha: 0.6);
      }
      .ant-tabs-content {
        .ant-tabs-tabpane {
          .liste-line {
            background: rgba($color: black, $alpha: 0.02);
          }
        }
      }
    }
  }

  // FIXING THEME COLORS - Must be in the end of the sheet

  // *[class$='primary']:not(.ant-btn) {
  //   background-color: $primary !important;
  //   border-color: $primary !important;
  // }
  .tile-edit-icons i {
    &:hover,
    &:focus {
      color: $primary !important;
      border-color: $primary !important;
    }
    &:active {
      color: $primary-d-10;
      border-color: $primary-d-10 !important;
    }
  }
  ::selection {
    background-color: $primary !important;
    background: $primary !important;
  }

  .ant-calendar-selected-start-date,
  .ant-calendar-selected-end-date,
  .ant-calendar-selected-date {
    .ant-calendar-date {
      background: $primary !important;
      border-color: $primary-d-10 !important;
    }
  }
  .ant-calendar-today {
    .ant-calendar-date {
      border-color: $primary;
    }
  }
  .ant-calendar-date {
    &:hover {
      background: $primary-l-10;
    }
  }

  .ant-calendar-range .ant-calendar-in-range-cell {
    &::before {
      background: $primary-l-15;
    }
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: $primary;
  }

  // FIXING TREE SELECTED
  .ant-tree li .ant-tree-node-content-wrapper {
    &:hover,
    &:active {
      background-color: rgba($color: black, $alpha: 0.2) !important;
    }
  }
  .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: $primary-l-50 !important;
  }

  .permission-list-collapse {
    background-color: rgba($color: black, $alpha: 0.1) !important;
    .ant-collapse-content {
      background: none;
    }
  }

  // FIXING PHONE SELECT
  .react-phone-number-input__input {
    color: black !important;
  }

  //Spiner
  .ant-spin-dot-item {
    background: $primary;
  }

  //Steps
  .ant-steps-item-finish {
    .ant-steps-item-icon {
      border-color: $primary;
      .ant-steps-icon {
        color: $primary !important;
      }
    }
  }
  .ant-steps-item-process {
    .ant-steps-item-icon {
      border-color: $primary;
      background: $primary;
    }
  }
  .ant-steps-item-custom {
    .ant-steps-item-icon {
      background: none;
    }
  }
  .ant-steps-item-process.ant-steps-item-custom {
    .ant-steps-item-icon {
      i {
        color: $primary;
      }
    }
  }
  .ant-steps-item-finish.ant-steps-item-custom {
    .ant-steps-item-icon {
      i {
        opacity: 0.4;
      }
    }
  }
  .settings-open {
    background-color: $l-content-bc;
  }
}
