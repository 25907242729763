/********************************** CONTENT ***********************************/
/*                                                                            */
/*    This file is for the CONTENT and all the components and applis in it    */
/*                                                                            */
/******************************************************************************/

.content-layout {
  overflow: auto;
  @media (max-width: 992px) {
    & {
      display: flex;
      flex: auto;
      flex-direction: column;
    }
  }
  .content {
    width: 100%;
    height: 100%;
    @media (max-width: 992px) {
      &.content-hidden {
        display: none;
      }
    }
    max-width: 1400px;
    display: flex;
    &.dashboard-content {
      max-width: initial;
      padding-right: 20px;
    }
  }
}

.actions {
  z-index: 10;
}

.ant-layout-content:not(.content) {
  transition: 0.3s;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($color: black, $alpha: 0.2);
    transform: scale(1);
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s;
  }
}
.settings-open {
  position: fixed;
  top: 85px;
  left: 20px;
  width: calc(100% - 440px);
  height: calc(100% - 105px);
  z-index: 10000;
  & > div {
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 100px rgba($color: black, $alpha: 0.2);
  }
  &::before {
    opacity: 1;
    transform: scale(2);
  }
}
.settings-drawer {
  .ant-drawer-content-wrapper {
    padding-top: 85px !important;
  }
}

.synoptic-parameters-section {
  .section-title {
    display: inline-block;
    font-size: 1.3em;
    margin-bottom: 10px;
  }
  .select-probe-type {
    display: flex;
    width: 100%;
    height: 60px;
    label {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
}

.color-param {
  display: flex;
  justify-content: space-between;
  input[type='color'] {
    padding: 1px;
    width: 32px;
  }
}

.range-param {
  display: flex;
  justify-content: space-between;
  & > div {
    flex-basis: 48%;
  }
}

.alias-param {
  display: flex;
  justify-content: space-between;
  & > div {
    flex-basis: 48%;
  }
}

.ant-tabs-nav .ant-tabs-tab-active {
  font-weight: initial;
}

@import 'nodesContent';
@import 'dashboardsContent';
@import 'managementContent';
@import 'phoneinput';
