.history-modal__head {
  position: relative;
  padding-left: 60px;
  .history-modal__head__time {
    .anticon {
      margin: 0;
    }
    font-size: 14px;
    opacity: 0.5;
  }
}

.history-modal__body {
  .history-modal__avatar {
    position: absolute;
    top: 23px;
    left: 25px;
    .ant-avatar {
      border-radius: 0;
    }
  }
  .history-modal__body__steps {
    width: 100%;
    background-color: rgba($color: lightgrey, $alpha: 0.1);
    border-bottom: solid 1px rgba($color: grey, $alpha: 0.1);

    .ant-collapse-header {
      padding: 10px 20px;
      min-height: 43px;
    }
    .ant-collapse-content {
      max-height: 240px;
      overflow: auto;
      .ant-collapse-content-box {
        padding: 0 20px;
      }
      .ant-steps-item-description {
        font-size: 12px;
        margin-top: -5px;
        padding-bottom: 0;
      }
    }
    .events-panel-count {
      transform: translateX(-20px);
      .ant-badge-count {
        color: grey;
        background-color: rgba($color: grey, $alpha: 0.1);
      }
    }
  }

  .history-modal__body__descriptions {
    margin-bottom: 10px;
    .ant-descriptions-title {
      margin-bottom: 5px;
    }
  }

  .ant-statistic-title {
    margin: 0;
  }
  .ant-statistic-content {
    font-size: 16px;
    line-height: 1.3em;
  }

  .history-modal__body__card-arrow {
    border: solid 1px rgba($color: lightgrey, $alpha: 0.5);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -37px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 1px;
  }

  .history-modal__body__message {
    padding: 10px;
    border: solid 1px rgba($color: lightgrey, $alpha: 0.1);
    border-radius: 10px;
    .receivers {
      display: flex;
      margin-bottom: 12px;
      align-items: center;
      border-bottom: solid 1px rgba($color: lightgrey, $alpha: 0.2);
      padding-bottom: 10px;
      & > span {
        white-space: nowrap;
      }
      .receivers_list {
        background-color: rgba($color: lightgrey, $alpha: 0.1);
        border-radius: 5px;
        padding: 4px 8px 2px;
        margin: 0 0 0 5px;
        word-break: break-all;
      }
    }
    .content {
      padding: 5px;
      margin: 0;
    }
  }
  .icon-aside {
    text-align: center;
    background-clip: text;

    .anticon {
      display: block;
      font-size: 60px;
    }
    code {
      font-size: 20px;
      letter-spacing: 0.1em;
      display: block;
    }
  }
}

.history-modal__footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 0 4px;
  & > span {
    padding: 5px 0;
    border-radius: 5px;
    &.antireplay {
      background-color: rgba($color: grey, $alpha: 0.1);
    }
  }
}

// REFERENCE TICKET
.reference-ticket__container {
  padding: 30px 0;
  background: rgba($color: lightgrey, $alpha: 0.1);
  display: flex;
  justify-content: center;
  .reference-ticket {
    position: relative;
    filter: drop-shadow(0 3px 4px rgba($color: black, $alpha: 0.1));
    width: 350px;

    .reference-ticket__infos {
      background: white;
      padding: 20px 20px 15px;
      border-radius: 16px 16px 4px 4px;
    }
    .separator {
      margin: -1px 0;
      background: white;
      width: 100%;
      height: 40px;
      clip-path: url(#ticketClip);
      -webkit-clip-path: url(#ticketClip);
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 30px;
        width: calc(100% - 60px);
        border-top: dashed 2px rgba($color: grey, $alpha: 0.1);
      }
    }
    .reference-ticket__barcode {
      position: relative;
      text-align: center;
      border-radius: 4px 4px 16px 16px;
      background: white;
      padding: 15px 0 10px;
      height: 155px;
      .barcode-container {
        animation: 0.2s ease-in-out barCode;
        overflow: hidden;
        position: relative;
        height: 100%;
        #barCodeCanvas {
          position: absolute;
          top: 0;
          left: 175px;
          transform: translateX(-50%);
        }
      }
    }
  }
}
.reference-canceled-tag {
  position: absolute;
  top: 15px;
  right: 15px;
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  opacity: 0.6;
  line-height: inherit;
  border-radius: 4px;
  transform: rotate(4deg);
  animation: 0.3s cubic-bezier(0.8, 0.01, 0.83, 0.67) canceledTag;
}

@keyframes barCode {
  from {
    transform: scale(0.95);
    opacity: 0.4;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes canceledTag {
  from {
    transform: scale(1.5) rotate(12deg);
    opacity: 0;
  }
  to {
    transform: scale(1) rotate(4deg);
    opacity: 0.6;
  }
}

.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  width: 8px !important;
  height: 8px !important;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot {
  left: 0;
  top: 1px;
}
