.responsive-table {
  position: relative;
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  .ant-spin-nested-loading > div > .ant-spin {
    top: 50%;
    left: 50%;
  }
  .ant-table-wrapper,
  .ant-spin-nested-loading,
  .ant-spin-container {
    display: flex;
    flex: 1;
    width: 100%;
  }
  .ant-spin-container,
  .ant-table {
    flex-direction: column;
    width: 100%;
  }
  .ant-spin-container {
    align-items: flex-end;
  }
  .ant-table-pagination {
    margin-bottom: 10px;
    //flex: 1;
  }
  .ant-table {
    display: flex;
    flex: 1;
    height: calc(100% - 50px);
    .ant-table-container {
      overflow: auto;
      .ant-table-content {
        height: 100%;
        overflow: auto auto !important;
      }
      .ant-table-thead {
        position: sticky;
        top: 0;
        z-index: 10;
      }
    }
  }

  .ant-table {
    background: none !important;
  }
}

.ant-table-title,
.ant-table-footer {
  z-index: 20;
  .ant-space-horizontal {
    width: 100%;
  }
  @media (max-width: 576px) {
    .ant-row div {
      width: 100%;
    }
  }
}
.ant-table-title {
  .ant-space-horizontal {
    justify-content: space-between;
  }
}

.table__dragger-upload.ant-upload.ant-upload-drag {
  position: relative;
  background: none;
  cursor: initial;
  border: none;
  &::after {
    pointer-events: none;
    position: absolute;
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    border-radius: 8px;
    border: dashed 1px transparent;
    color: rgba($color: #1890ff, $alpha: 0);
    font-size: 1.2em;
    transition: 0.2s;
  }
  &.ant-upload-drag-hover {
    &::after {
      content: 'DROP HERE';
      color: #1890ff;
      background: rgba($color: #1890ff, $alpha: 0.1);
      border-color: #1890ff;
    }
  }

  .anticon-plus {
    color: inherit !important;
    font-size: inherit !important;
    transition: none;
  }
}
