// BORDER RADIUS

$borderRadius4: 4px;
$borderRadius8: 8px;

// Input & Button

.ant-btn,
.ant-input,
.ant-input-affix-wrapper,
.ant-input-group-addon,
.ant-upload.ant-upload-drag,
.ant-input-number,
.ant-select-selector,
.ant-picker,
.ant-picker-focused {
  border-radius: $borderRadius4;
}
.ant-btn-circle {
  border-radius: 50%;
}
.ant-select {
  &:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: $borderRadius4;
    background-color: transparent;
  }
}
.ant-input-group-compact {
  & > div {
    .ant-btn,
    .ant-input,
    .ant-input-affix-wrapper,
    .ant-input-group-addon,
    .ant-upload.ant-upload-drag,
    .ant-input-number,
    .ant-select-selector,
    .ant-picker,
    .ant-picker-focused {
      border-radius: 0 !important;
    }
    &:first-of-type {
      border-radius: $borderRadius4 0 0 $borderRadius4 !important;
      .ant-btn,
      .ant-input,
      .ant-input-affix-wrapper,
      .ant-input-group-addon,
      .ant-upload.ant-upload-drag,
      .ant-input-number,
      .ant-select-selector,
      .ant-picker,
      .ant-picker-focused {
        border-radius: $borderRadius4 0 0 $borderRadius4 !important;
      }
      .ant-select-single,
      .ant-select-multiple {
        &:not(.ant-select-customize-input) .ant-select-selector {
          border-radius: $borderRadius4 0 0 $borderRadius4 !important;
        }
      }
    }
    &:last-of-type {
      border-radius: 0 $borderRadius4 $borderRadius4 0 !important;
      .ant-btn,
      .ant-input,
      .ant-input-affix-wrapper,
      .ant-upload.ant-upload-drag,
      .ant-input-number,
      .ant-select-selector,
      .ant-picker,
      .ant-picker-focused {
        border-radius: 0 $borderRadius4 $borderRadius4 0 !important;
      }
      .ant-select-single,
      .ant-select-multiple {
        &:not(.ant-select-customize-input) .ant-select-selector {
          border-radius: 0 $borderRadius4 $borderRadius4 0 !important;
        }
      }
    }
    &:only-of-type {
      .ant-btn,
      .ant-input,
      .ant-input-affix-wrapper,
      .ant-upload.ant-upload-drag,
      .ant-input-number,
      .ant-select-selector,
      .ant-picker,
      .ant-picker-focused {
        border-radius: $borderRadius4 !important;
      }
      .ant-select-single,
      .ant-select-multiple {
        &:not(.ant-select-customize-input) .ant-select-selector {
          border-radius: $borderRadius4 !important;
        }
      }
    }
  }
}

// Table
.ant-table,
.ant-descriptions-view {
  border-radius: $borderRadius4;
}
.ant-table-title {
  border-radius: $borderRadius4 $borderRadius4 0 0;
}
.ant-table-footer {
  border-radius: 0 0 $borderRadius4 $borderRadius4;
}

// Modal
.ant-modal-content {
  border-radius: $borderRadius8;
}
.ant-modal-header {
  border-radius: $borderRadius8 $borderRadius8 0 0;
}

// Other
.sync-handler__container,
.user-infos__image,
.ant-notification-notice {
  border-radius: $borderRadius8;
}
.ant-tooltip-inner,
.ant-alert,
.nb-users {
  border-radius: $borderRadius4;
}
.actions,
.management-content,
.stats-container,
.tile,
.ant-card:not(.sider__list__item__card),
.sider__list__item__card,
.ant-dropdown-menu,
.user-avatar {
  border-radius: $borderRadius8;
  span:not(.anticon):not(.ant-tag):not(.ant) {
    color: var(--textColor);
  }
}
.ant-radio-button-wrapper {
  &:first-child {
    border-radius: $borderRadius4 0 0 $borderRadius4 !important;
  }
  &:last-child {
    border-radius: 0 $borderRadius4 $borderRadius4 0;
  }
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  border-radius: $borderRadius4;
  transform: translateX(2px);
}

.tree-filter .ant-menu {
  border: none;
}

.object-handler-container {
  border-radius: 8px;
}

// BOX SHADOW

.object-handler-container,
.management-content {
  box-shadow: 0 3px 6px -2px rgba($color: #31313d, $alpha: 0.2);
}

// Button
.ant-btn {
  &.ant-btn-dangerous {
    color: var(--dangerColor);
    background-color: inherit;
    &:not(.ant-btn-text) {
      border-color: var(--dangerColor) !important;
      box-shadow: 0 4px 15px -8px var(--dangerColor);
    }
    span {
      color: var(--dangerColor) !important;
    }
    &.ant-btn-sm {
      //color: var(--textPrimaryColor);
      box-shadow: none !important;
    }
  }
  &.ant-btn-primary:not([disabled]) {
    // box-shadow: 0 4px 15px -8px rgba($color: #1890ff, $alpha: 1);
    text-shadow: none;
    span {
      color: #fff;
    }
  }
  &.ant-btn-ghost:not([disabled]),
  &.ant-btn-text span {
    color: var(--textColor);
  }
}

// MARGIN & PADDING

// Table

.ant-table.ant-table-middle {
  .ant-table-title,
  .ant-table-footer {
    padding: 10px;
  }
}

.ant-drawer-content-wrapper {
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.52, 0.01, 0.14, 1.14) !important;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
  .ant-drawer-content {
    box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05),
      -12px 0 48px 16px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    overflow: hidden !important;
  }
}
.ant-drawer-right .ant-drawer-content-wrapper {
  padding: 20px;
  .ant-drawer-content {
    box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05),
      -12px 0 48px 16px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    overflow: hidden !important;
  }
}
.navbar__menu .ant-menu-item {
  border-radius: $borderRadius8;
}
