/****************************** LOGO ******************************/
/*                                                                */
/*     This file is for the LOGO and all the components in it     */
/*                                                                */
/******************************************************************/

.topbar-logo-container {
  display: flex;
  align-items: center;
  .logo {
    height: 38px;
  }
}
