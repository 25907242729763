.object-handler {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  //overflow: hidden;
  .object-handler-head {
    padding: 20px 20px 0 20px;
    @media (max-width: 768px) {
      padding: 10px 10px 0 10px;
    }
    // BREADCRUMB
    .object-breadcrumb {
      min-height: 66px;
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      & > ol > li {
        display: flex;
        .object-breadcrumb-item {
          transition: opacity 0.1s;
          opacity: 0.6;
          .object-breadcrumb-item-node-alias {
            color: var(--textLightColor) !important;
          }
          a {
            display: flex;
            align-items: center;
            color: inherit !important;
          }
          &:hover {
            opacity: 1;
          }
          img {
            object-fit: contain;
          }
        }
        &:last-of-type {
          min-width: 100%;
          .object-breadcrumb-item {
            opacity: 1;
            font-size: 28px;
          }
        }
        &:nth-last-of-type(2) {
          .ant-breadcrumb-separator {
            display: none;
          }
        }
      }
    }
  }
  //OBJECT HANDLER CONTENT
  .object-handler-content {
    z-index: 1;
    overflow: auto;
    padding: 10px 20px 20px 20px;
    @media (max-width: 768px) {
      padding: 10px;
    }
    height: 100%;
    &.no-tabs {
      height: 437px;
    }
    .network-status-img {
      position: absolute;
      transform: translateY(-100%);
      top: 0;
      right: 20px;
    }
    .ant-tabs {
      &.options-card-tabs {
        .ant-tabs-nav {
          .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
              flex: initial;
              border: solid 1px rgba($color: lightgrey, $alpha: 0.3);
              overflow: hidden;
              .ant-tabs-tab {
                padding: 10px 25px 10px 10px;
                margin: 0;
                border-bottom: solid 1px rgba($color: lightgrey, $alpha: 0.3);
                &:nth-last-child(2) {
                  border-bottom: none;
                }
                &.ant-tabs-tab-active {
                  background-color: rgba($color: lightgrey, $alpha: 0.05);
                }
                .ant-badge-status-text {
                  display: none;
                }
              }
            }
          }
        }
        .ant-tabs-content-holder {
          height: 100%;
          border: none;
        }
      }

      .ant-tabs-content-holder {
        .ant-tabs-content {
          height: 100%;
          max-height: 100%;
        }
      }
    }

    .object-handler-header {
      display: flex;
      align-items: flex-end;
      position: sticky;
      top: 0;
      z-index: 10;

      .sync-handler__container {
        padding: 5px;
        background: white;
        box-shadow: 0 10px 20px -10px rgba($color: black, $alpha: 0.3);
      }
    }

    .object-handler-container {
      position: relative;
      display: flex;
      padding: 10px;
      flex-direction: column;
      min-height: 50px;
      height: max-content;
      max-height: 100%;
      background: white;
      overflow-y: auto;
      overflow-x: hidden;
      @media (max-width: 768px) {
        border-radius: 0;
        padding: 12px;
        box-shadow: none;
        height: 100%;
      }
      &.full-height {
        height: 100%;
      }
      .ant-layout {
        position: relative;
        height: auto !important;
      }

      .object-handler__echart {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
      }

      & > .ant-form {
        width: 100%;
      }

      .object-handler__table-container {
        width: 100%;
      }

      .flex-pairing {
        flex: 1;
      }

      .pairing-firmeware-upload {
        display: flex;
        .ant-form-item-control-input-content {
          display: flex;
          justify-content: space-between;
          & > span {
            flex: 1;
            margin-right: 20px;
          }
        }
      }
    }
  }
}

// LOCKERS

.lockers-icon {
  font-size: 1.5em;
}

// STATISTIC

.stats-container {
  position: relative;
  background: rgba($color: lightgrey, $alpha: 0.1);
  border: solid 1px rgba($color: lightgrey, $alpha: 0.3);
  padding: 20px;
  padding-bottom: 0;
  border-radius: 2px;
  margin: 30px 10px 0 10px;
  min-height: 60px;
  .stats-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    flex-wrap: wrap;
    .stats-header__title {
      font-size: 1.2em;
      font-weight: bold;
      margin-right: 20px;
    }
    .stats-header__actions {
      display: flex;
      align-items: center;
    }
  }
  .stats-body {
    .echarts-for-react {
      min-height: 400px;
    }
  }
}

// NOTIFICATIONS

.notification-handler-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  @media (max-width: 992px) {
    flex-direction: column;
  }
}
.notification-config-form {
  border-right: solid 1px rgba($color: lightgrey, $alpha: 0.3);
  overflow: auto;
  overflow-x: hidden;
  padding: 10px 15px;
  width: max-content;
  width: 300px;
  @media (max-width: 992px) {
    width: 100%;
    border-right: none;
    border-bottom: solid 1px rgba($color: lightgrey, $alpha: 0.3);
    margin-bottom: 20px;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
}

.notification-select-form-container {
  flex: 1;
  padding: 10px 20px;
  height: 100%;
  overflow: auto;
  .notification-select-form {
    height: 100%;
    .notif-select-card-formitem {
      height: calc(100% - 154px);
      .ant-form-item-control {
        height: 100%;
        .ant-form-item-control-input {
          height: 100%;
          .ant-form-item-control-input-content {
            height: 100%;
            .ant-card {
              height: 100%;
              display: flex;
              flex-direction: column;
              .ant-card-body {
                height: 100%;
                padding-bottom: 0;
                overflow: auto;
              }
            }
          }
        }
      }
    }
  }

  .draggable-liste {
    position: relative;
    //background-color: white;
    border: solid 1px rgba($color: grey, $alpha: 0.3);
    border-radius: 2px;
    margin-top: -28px;
    margin-bottom: 24px;
    border-radius: 0 0 4px 4px;
    z-index: 1;
    .ant-collapse-header {
      padding: 8px 12px;
      .ant-collapse-arrow {
        padding: 0;
        top: 14px;
        left: 12px;
      }
    }
    .ant-collapse-content-box {
      padding: 10px;
      padding-top: 0 !important;
    }
    .ant-collapse-item {
      border: none;
    }
    .draggable-liste-item {
      display: inline-block;
      background: rgba($color: grey, $alpha: 0.1);
      margin: 2px;
      padding: 1px 4px 2px;
      border-radius: 4px;
      cursor: grab;
      &:hover {
        background: rgba($color: grey, $alpha: 0.2);
      }
      &:active {
        cursor: grabbing;
      }
    }
  }
}

// MACHINE
.machine-tab-row {
  flex-wrap: nowrap;
  .machine-tab-machine-col {
    margin-right: 20px;
    flex: 0;
  }
}

// PAIRING

.pairing-descriptions {
  .ant-descriptions-title {
    font-weight: normal;
  }
}

.pairing-tab {
  height: 100%;
  .pairing__machine-infos {
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 180px;
      object-fit: contain;
      margin: 20px 0;
    }
  }
  .pairing__infos {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
  }
}

// SETTINGS

.object-handler-container.settings {
  @media (max-width: 992px) {
    padding: 0 !important;
  }
}

@import 'historyModal';
