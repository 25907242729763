.react-phone-number-input {
  margin-top: 4px;
}

.react-phone-number-input__row {
  display: flex;
  align-items: center;
}

.react-phone-number-input__phone {
  flex: 1;
  min-width: 0;
}

.react-phone-number-input__icon {
  /* The flag icon size is 4x3 hence the exact `width` and `height` values */
  width: 1.24em;
  height: 0.93em;

  /* `1px` is still too much for a "retina" screen but there's no way in CSS to specify "hairline" border width. */
  border: 1px solid rgba(0, 0, 0, 0.5);

  /* Makes sure `width`x`height` is exactly `4x3` and `border` width is not included in it */
  box-sizing: content-box;
}

.react-phone-number-input__icon--international {
  /* The international icon size is square */
  /* and also has no border hence the `2 * 1px` `width` and `height` compensation */
  width: calc(0.93em + 2px);
  height: calc(0.93em + 2px);

  /* The international icon size is square hence the exact `padding` value */
  /* for precise alignment with `4x3` sized country flags. */
  padding-left: 0.155em;
  padding-right: 0.155em;
  border: none;
}
.react-phone-number-input__error {
  margin-left: calc(1.24em + 2px + 0.3em + 0.35em + 0.5em);
  margin-top: calc(0.3rem);
  color: red;
}
.react-phone-number-input__icon-image {
  max-width: 100%;
  max-height: 100%;
  vertical-align: top;
}

// just changed
.react-phone-number-input__ext-input {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    margin: 0 !important;
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
  }
}

.react-phone-number-input__ext-input {
  width: 3em;
}
.react-phone-number-input__ext {
  white-space: nowrap;
}
.react-phone-number-input__ext,
.react-phone-number-input__ext-input {
  margin-left: 0.5em;
}
.react-phone-number-input__country--native {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0.5em;
}

.react-phone-number-input__country-select {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.react-phone-number-input__country-select-divider {
  font-size: 1px;
  background: rgba($color: black, $alpha: 0.4);
}

.react-phone-number-input__country-select:focus + .react-phone-number-input__country-select-arrow {
  color: #03b2cb;
}

/* Styling phone number input */

.react-phone-number-input__input {
  border: solid 1px !important;
  border-radius: 4px !important;
  padding: 4px 10px !important;
  height: 32px;
}

.react-phone-number-input__input:focus {
  border-color: #fc9d28;
}

.react-phone-number-input__input--disabled {
  cursor: default;
}

.react-phone-number-input__input--invalid,
.react-phone-number-input__input--invalid:focus {
  border-color: red;
}

/* Overrides Chrome autofill yellow background color */
.react-phone-number-input__input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #333333 inset;
  /* -webkit-text-fill-color: white; */ /* Comment just for a temporary fix*/
}

.react-phone-number-input__input:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px #333333 inset;
  /* -webkit-text-fill-color: white; */ /* Comment just for a temporary fix*/
}
/* A small fix for `react-responsive-ui` */
.react-phone-number-input__country .rrui__select__button {
  border-bottom: none;
}

.input-select-countryCodeSelector .ant-input-group-addon {
  width: 50%;
  padding: 0;
}
