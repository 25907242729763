.management-content {
  max-height: 600px;
  margin: 40px 20px 0 40px;
  display: flex;
  @media (max-width: 1200px) {
    max-height: unset;
    margin: 0;
    border-radius: 0 !important;
    flex-direction: column;
    width: 100%;
  }
}

.user-drawer__user-access-container {
  max-height: 600px;
  @media (max-width: 1200px) {
    max-height: unset;
  }
  .ant-tabs-nav-operations {
    position: relative !important;
  }
}

.management-tabs {
  overflow-x: hidden;
  overflow-y: auto;
  @media (max-width: 1200px) {
    overflow-y: unset;
  }
}

.user__infos__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border-right: solid 1px rgba($color: lightgrey, $alpha: 0.2);
  @media (max-width: 768px) {
    padding: 20px 12px;
  }
}

.accesstype__manage-confirm__button {
  margin-bottom: 0;
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
  }
}
