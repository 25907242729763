@use 'sass:color';

@mixin theme($text, $lightText, $textPrimary, $primary, $background) {
  --textColor: #{$text};
  --textSecondaryColor: #{lighten($text, 30)};
  --textDisabledColor: #{lighten($text, 50)};
  --textLightColor: #{$lightText};
  --textPrimaryColor: #{$textPrimary};
  --primaryColor: #{$primary};
  --primaryColorLighter005: #{lighten($primary, 0.05%)};
  --primaryColorLighter10: #{lighten($primary, 10%)};
  --primaryColorLighter15: #{lighten($primary, 15%)};
  --primaryColorLighter20: #{lighten($primary, 20%)};
  --primaryColorLighter40: #{lighten($primary, 40%)};
  --primaryColorLighter50: #{lighten($primary, 50%)};
  --primaryColorLighter70: #{lighten($primary, 70%)};
  --primaryColorDarker10: #{darken($primary, 10%)};
  --primaryColorDarker15: #{darken($primary, 15%)};

  --backgroundColor: #{$background};
  --backgroundColorLighter8: #{lighten($background, 8%)};
  --backgroundColorLighter10: #{lighten($background, 10%)};
  --backgroundColorLighter20: #{lighten($background, 20%)};
  --backgroundSecondary: #{lighten($background, 10%)};

  --menuItemShadow: #{desaturate(lighten($primary, 20), 40)};
}

.theme-light {
  @include theme($text: #000000d9, $lightText: #343862, $textPrimary: #fff, $primary: #211c51, $background: #fafafa);
  --finderSelectedNodeColor: #bae7ff;

  --dangerColor: #ff4d4f;
}

.theme-dark {
  @include theme($text: white, $lightText: #343862, $textPrimary: black, $primary: #f5f5f5, $background: #111);
  --finderSelectedNodeColor: #000;

  --dangerColor: red;
}

.theme-nord {
  @include theme($text: #eceff4, $lightText: #d8dee9, $textPrimary: #e5e9f0, $primary: #5e81ac, $background: #2e3440);
  --finderSelectedNodeColor: #4c566a;

  --dangerColor: #bf616a;
  --warningColor: #d08770;
  --infoColor: #5e81ac;
  --successColor: #a3be8c;
}

$d-overlay-elem-bc: #302e2e;
$d-button-bc: #282626;
$d-button-bc-d-2: darken($d-button-bc, 2%);
$d-button-bc-l-2: lighten($d-button-bc, 2%);
$d-input-bc: #2b2929;

//** LIGHT **//
$l-topbar-bc: #dfdcdc;
$l-sider-bc: #f3f0f0;
$l-content-bc: #faf8f8;
$l-text-principal-c: black;

body {
  background: var(--backgroundColor);

  .ant-tabs-bar {
    border-color: var(--primaryColorLighter50);
  }

  .ant-form-item-label > label,
  .ant-tree,
  .ant-table,
  .ant-table-thead > tr > th,
  .ant-table-cell,
  .ant-tabs,
  .ant-typography,
  .ant-select:not(.ant-select-multiple),
  .ant-select .ant-select-arrow {
    color: var(--textColor);
  }

  .ant-typography-secondary {
    color: var(--textLightColor) !important;
  }

  .ant-pagination {
    color: var(--textColor);
    button[disabled] span {
      color: var(--textDisabledColor);
    }
    span,
    li:not(.ant-pagination-item-active) a,
    .ant-pagination-item-ellipsis {
      color: var(--textColor);
      border-color: inherit;
    }
  }

  .object-handler {
    color: var(--textColor);
    .object-handler-content {
      .object-handler-container {
        background: var(--backgroundSecondary);
        .ant-layout {
          background-color: var(--primaryColorLighter005);
        }
      }
    }
  }
  .ant-table-row-expand-icon {
    background-color: var(--backgroundSecondary);
  }

  .nb-users {
    background: var(--primaryColorLighter005);
  }

  // DASHBOARDS
  .content-layout {
    .react-grid-item {
      .tile {
        background: var(--backgroundSecondary);
        .ant-layout {
          background: none;
        }
        &.sync-tile {
          border-color: rgba($color: var(--primaryColor), $alpha: 0.5);
        }
      }
    }
  }

  .react-grid-layout {
    .react-grid-item {
      .tile-edit-icons {
        background: rgba($color: black, $alpha: 0.2);
        border: dotted 2px rgba($color: black, $alpha: 0.5);
        i {
          background: rgba($color: white, $alpha: 0.8);
        }
      }
      .react-resizable-handle {
        &:after {
          // Handler resize
          border-color: rgba($color: black, $alpha: 0.5);
        }
      }
    }
  }

  .sider-dashboard-settings {
    background-color: var(--primaryColorLighter10);
    box-shadow: 0 0 20px rgba($color: black, $alpha: 0.3);
  }

  .management-content {
    background: rgba($color: white, $alpha: 0.03);
  }

  .permission-modal {
    .ant-modal-body {
      .ant-tabs-bar {
        box-shadow: 0 10px 25px -15px rgba($color: black, $alpha: 0.8);
      }
      .ant-tabs-content {
        .ant-tabs-tabpane {
          .liste-line {
            background: rgba($color: white, $alpha: 0.02);
          }
        }
      }
    }
  }

  // FIXING THEME COLORS - Must be in the end of the sheet
  .ant-form-item-control-input-content .ant-space-item {
    color: var(--textColor);
  }

  .tile-edit-icons i,
  .download-btn {
    background-color: $d-button-bc !important;
    border-color: #999;
    color: var(--textColor);
    &:hover,
    &:focus {
      color: var(--primaryColor);
      border-color: var(--primaryColor) !important;
      background-color: $d-button-bc-l-2 !important;
      .anticon,
      span {
        color: var(--primaryColor) !important;
        transition: 0.3s;
      }
    }
    &:active {
      border-color: var(--primaryColorDarker10) !important;
      background-color: $d-button-bc-d-2 !important;
      .anticon,
      span {
        color: var(--primaryColorDarker10) !important;
        transition: 0.1s;
      }
    }
  }
  ::selection {
    background-color: var(--backgroundColor) !important;
    background: var(--primaryColor) !important;
  }
  .ant-card,
  .ant-select-dropdown,
  .ant-modal-content,
  .ant-dropdown-menu,
  .ant-popover-inner,
  .ant-message-notice-content,
  .ant-drawer-content,
  .ant-calendar,
  .ant-calendar-input,
  .ant-table-filter-dropdown {
    color: var(--textColor);
    background-color: var(--backgroundColorLighter8);
  }
  .ant-popover-arrow {
    border-color: $d-overlay-elem-bc transparent transparent $d-overlay-elem-bc !important;
  }
  .ant-card-actions {
    background: none !important;
  }
  .ant-card-meta-description {
    color: #666;
    .anticon {
      color: #666;
    }
  }
  .ant-modal-header {
    background-color: inherit;
    border-color: rgba($color: white, $alpha: 0.3);
  }
  .ant-dropdown-menu-item:hover {
    background-color: var(--backgroundColor);
  }
  .ant-select-dropdown-menu-item,
  .ant-tree-node-content-wrapper,
  .ant-select-tree-node-content-wrapper {
    &:hover {
      background-color: var(--finderSelectedNodeColor) !important;
    }
  }
  .ant-select-dropdown-menu-item-selected,
  .ant-select-dropdown-menu-item-active,
  .ant-select-selection__choice {
    background-color: rgba($color: white, $alpha: 0.3) !important;
  }
  .ect-handler {
    background-color: var(--primaryColorLighter10) !important;
  }
  .synoptic-form {
    background-color: var(--primaryColorLighter005) !important;
  }
  .serie-collapse {
    background: none !important;
    .serie-list-panel {
      background: var(--primaryColorLighter10);
      .serie-param-collapse {
        background: none !important;
      }
    }
  }
  .has-error .ant-form-explain span,
  .has-error .ant-form-split {
    color: tomato !important;
  }

  .ant-calendar-selected-start-date,
  .ant-calendar-selected-end-date,
  .ant-calendar-selected-date {
    .ant-calendar-date {
      background: var(--primaryColor) !important;
      border-color: var(--primaryColorDarker10) !important;
    }
  }
  .ant-calendar-today {
    .ant-calendar-date {
      border-color: var(--primaryColor);
    }
  }
  .ant-calendar-date {
    &:hover {
      background: var(--primaryColorLighter10);
    }
  }

  .ant-calendar-range .ant-calendar-in-range-cell {
    &::before {
      background: var(--primaryColorLighter15);
    }
  }

  .ant-checkbox-wrapper {
    color: var(--textColor);
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: var(--backgroundColor);
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--primaryColor);
  }

  // FIXING BOX SHADOW
  .ant-collapse-item.ant-collapse-item-active.serie-list-panel {
    & > .ant-collapse-header {
      box-shadow: 0 10px 18px -20px rgba($color: black, $alpha: 1) !important;
    }
  }
  .ant-card-hoverable:hover {
    box-shadow: 0 15px 13px -20px black;
  }

  // FIXING TABLE
  .ant-table {
    thead th {
      background-color: var(--backgroundColor) !important;
    }
    tbody {
      tr:hover {
        background-color: var(--backgroundColorLighter8) !important;
        td,
        td:hover {
          background: none !important;
        }
      }
      .ant-table-column-sort {
        background: var(--backgroundColor);
      }
      td:hover {
        background: none !important;
      }
    }
    .ant-table-placeholder {
      background: none;
    }
    .ant-table-footer {
      background: none;
    }
  }
  .ant-pagination {
    li,
    a {
      background: none !important;
    }
  }

  // FIXING TREE SELECTED
  .ant-tree {
    background-color: var(--backgroundSecondary);
  }
  .ant-tree li .ant-tree-node-content-wrapper {
    &:hover,
    &:active {
      background-color: rgba($color: black, $alpha: 0.4) !important;
    }
  }
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: var(--finderSelectedNodeColor) !important;
    box-shadow: 0 5px 10px -5px var(--finderSelectedNodeColor);
  }

  .add-dashboard-modal {
    .ant-radio-button-wrapper {
      background: none !important;
    }
  }
  .account-modal {
    .card-object {
      background: rgba($color: black, $alpha: 0.2) !important;
    }
  }

  // FIXING PHONE SELECT
  .react-phone-number-input__country-select {
    background-color: $d-overlay-elem-bc !important;
    color: white !important;
  }

  // FIXING MESSAGE ICON COLOR
  .ant-message-success {
    .anticon {
      color: #00ff00 !important;
    }
  }

  // FIXING ANTICON SVG COLOR
  .anticon {
    & > svg {
      color: inherit !important;
      & > path {
        color: inherit !important;
      }
    }
  }

  .ant-alert-error {
    background-color: tomato;
  }

  // FIXING BOOKMARK STAR
  .ant-rate {
    color: #fadb14 !important;
    .ant-rate-star.ant-rate-star-full {
      color: inherit !important;
      & > div {
        color: inherit !important;
        .ant-rate-star-first,
        .ant-rate-star-second {
          color: inherit !important;
          .anticon-star {
            color: inherit !important;
          }
        }
      }
    }
  }

  //Spiner
  .ant-spin-dot-item {
    background: var(--primaryColor);
  }

  //Steps
  .ant-steps-item-finish {
    .ant-steps-item-icon {
      border-color: var(--primaryColor);
      .ant-steps-icon {
        color: var(--primaryColor) !important;
      }
    }
  }
  .ant-steps-item-process {
    .ant-steps-item-icon {
      border-color: var(--primaryColor);
      background: var(--primaryColor);
    }
  }
  .ant-steps-item-wait {
    .ant-steps-item-icon {
      border-color: white;
    }
  }
  .ant-steps-item-custom {
    .ant-steps-item-icon {
      background: none;
    }
  }
  .ant-steps-item-process.ant-steps-item-custom {
    .ant-steps-item-icon {
      i {
        color: var(--primaryColor);
      }
    }
  }
  .ant-steps-item-finish.ant-steps-item-custom {
    .ant-steps-item-icon {
      i {
        opacity: 0.4;
      }
    }
  }

  .ant-tooltip-arrow {
    border-color: rgba($color: black, $alpha: 0) rgba($color: black, $alpha: 0.75) rgba($color: black, $alpha: 0.75)
      rgba($color: black, $alpha: 0);
  }

  .settings-open {
    background-color: $d-overlay-elem-bc;
  }
}
