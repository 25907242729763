@import '../../mixins/index';

//** DARK **//
$d-topbar-bc: #171515;
$d-sider-bc: #1e1c1c;
$d-content-bc: #252323;
$d-overlay-elem-bc: #302e2e;
$d-button-bc: #282626;
$d-button-bc-d-2: darken($d-button-bc, 2%);
$d-button-bc-l-2: lighten($d-button-bc, 2%);
$d-input-bc: #2b2929;
$d-text-principal-c: whitesmoke;

//** LIGHT **//
$l-topbar-bc: #dfdcdc;
$l-sider-bc: #f3f0f0;
$l-content-bc: #faf8f8;
$l-text-principal-c: black;

//** PRIMARY **//
$primary: #211c51;
$primary-l-10: lighten($primary, 10%);
$primary-d-10: darken($primary, 10%);
$primary-d-15: darken($primary, 15%);
$primary-l-15: lighten($primary, 15%);
$primary-l-50: lighten($primary, 50%);

//** Themes **//
@import 'dark';
@import 'light';

.color-options.dark-span,
.select-color.dark-span .ant-select-selection-selected-value {
  span {
    &:first-child {
      background: $d-content-bc;
      border: solid 1px #bbb;
      &::after {
        background: $primary;
      }
    }
  }
}

.color-options.light-span,
.select-color.light-span .ant-select-selection-selected-value {
  span {
    &:first-child {
      background: $l-content-bc;
      border: solid 1px #444;
      &::after {
        background: $primary;
      }
    }
  }
}

.color-options.nord-span,
.select-color.nord-span .ant-select-selection-selected-value {
  span {
    &:first-child {
      background: #2e3440;
      border: solid 1px #4c566a;
      &::after {
        background: #eceff4;
      }
    }
  }
}

.tab-sync.active {
  &::before {
    border: solid 4px $primary;
  }
}
