.loading__img {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  animation-name: blink;
  animation-duration: 1s;
  animation-delay: .3s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes blink {
  from { opacity: 0; }
  to { opacity: 1; }
}
