// DASHBOARDS LIST

.dashboard-sider__list,
.users-sider__list {
  .sider__list__item {
    border-bottom: none;
    .sider__list__item__link {
      width: 100%;
    }
  }
}

.dashboards__all-title {
  margin: 10px 20px 0;
  color: var(--textColor);
  opacity: 0.8;
}

.dashboard-sider__list {
  padding: 0 20px;
}
.users-sider__list {
  padding: 15px 20px;
}

.sider__list__item {
  padding: 8px 0;
  position: relative;
  z-index: 10;
  .sider__list__item__card {
    transition: 0.2s;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1px;
      height: 12px;
      width: 4px;
      background: var(--primaryColor);
      border-radius: 10px;
      transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      z-index: -1 !important;
    }
    &:hover {
      box-shadow: 0 6px 18px -8px rgba($color: black, $alpha: 0.1);
    }
    &.active {
      box-shadow: 0 8px 20px -10px rgba($color: black, $alpha: 0.3);

      &::before {
        transform: translate(8px, -50%);
      }
    }
    .ant-card-body {
      display: flex;
      align-items: center;
      padding: 10px;
      color: var(--primaryColor);
      .ant-avatar {
        border-radius: 0;
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
      .sider__list__name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1;
        margin-right: 20px;
      }
    }
  }
}

.sider__list__item__actions {
  button {
    background-color: rgba($color: #b4b7ba, $alpha: 0.15);
    color: #b4b7ba;
  }
  .dashboard-delete-button {
    &:hover {
      background-color: rgba($color: #f13452, $alpha: 0.15);
      color: #f13452;
    }
  }
  .bookmark-star-button {
    &:hover {
      background-color: rgba($color: #ffe600, $alpha: 0.15);
      color: #e7d000;
    }
  }
}
.add-dashboard-modal {
  position: absolute;
  top: auto;
  left: 20px;
  bottom: 40px;
  .ant-modal-header {
    padding: 15px;
    .ant-modal-title {
      padding-right: 20px;
    }
  }
  .ant-modal-body {
    padding: 15px;
  }
}

.dashboard-add__radio-group {
  width: 100%;
  display: flex;
  .ant-radio-button-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: unset;
    padding: 6px;
    height: auto;
  }
}
