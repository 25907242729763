.super-admin-tabs {
  display: flex;
  .ant-tabs-content-holder {
    display: flex;
  }

  .ant-spin-nested-loading {
    width: 100%;
  }
}

.super-admin-object-handler {
  padding: 20px 20px 10px 20px;
  overflow: hidden;
  .object-handler-content {
    padding: 0;
    overflow: visible;
    .ant-tabs {
      .ant-tabs-content-holder {
        padding-top: 0;
      }
    }
    .object-handler-container {
      background: none;
      border-radius: 0;
      box-shadow: none;
      padding: 0;
      overflow: visible;
    }
  }
}
