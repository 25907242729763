.react-grid-layout {
  .react-grid-item {
    .tile-edit-icons {
      color: var(--textLightColor);
      background: var(--backgroundColor);
      border: dotted 2px var(--textLightColor);
    }
    .react-resizable-handle {
      $padding: 5px;
      $size: 15px;
      $thickness: 4px;
      $border: $thickness solid var(--textLightColor);
      &::after {
        content: ' ';
        position: absolute;
        // background-color: red;
        width: $size;
        height: $size;
      }
      &.react-resizable-handle-s::after {
        bottom: $padding;
        left: 50%;
        border-bottom: $border;
        transform: translateX(-50%);
        cursor: s-resize;
      }
      &.react-resizable-handle-w::after {
        top: 50%;
        left: $padding;
        border-left: $border;
        transform: translateY(-50%);
        cursor: w-resize;
      }
      &.react-resizable-handle-e::after {
        top: 50%;
        right: $padding;
        border-right: $border;
        transform: translateY(-50%);
        cursor: e-resize;
      }
      &.react-resizable-handle-n::after {
        top: $padding;
        left: 50%;
        border-top: $border;
        transform: translateX(-50%);
        cursor: n-resize;
      }
      &.react-resizable-handle-sw::after {
        bottom: $padding;
        left: $padding;
        border-left: $border;
        border-bottom: $border;
        cursor: sw-resize;
      }
      &.react-resizable-handle-nw::after {
        top: $padding;
        left: $padding;
        border-top: $border;
        border-left: $border;
        cursor: nw-resize;
      }
      &.react-resizable-handle-se::after {
        bottom: $padding;
        right: $padding;
        border-right: $border;
        border-bottom: $border;
        cursor: se-resize;
      }
      &.react-resizable-handle-ne::after {
        top: $padding;
        right: $padding;
        border-right: $border;
        border-top: $border;
        cursor: ne-resize;
      }
    }
    .react-resizable-handle {
      &:after {
        // Handler resize
        border-color: rgba($color: black, $alpha: 0.5);
      }
    }
  }
}
