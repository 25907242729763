.permission-list-collapse {
  margin-bottom: 20px;
  .ant-collapse-item {
    .ant-collapse-content {
      border-color: rgba($color: white, $alpha: 0.3);
    }
  }
  .ant-list-item-content {
    display: block !important;
  }
}
.permission-list-actions {
  float: right;
  padding-right: 5px;
  i {
    padding: 0 2px;
    margin: 0 4px;
  }
}

.permissions-panel {
  .new-line,
  .liste-line {
    display: flex;
    margin: 6px 0;
    .line-section {
      & > .ant-row {
        width: 100%;
      }
      flex: 1;
      display: flex;
      margin-right: 5px;
      .ant-select {
        flex: 1;
      }
      &.name {
        display: flex;
        align-items: center;
        background-color: rgba($color: grey, $alpha: 0.05);
        border-radius: 4px;
        span {
          padding: 0 10px;
        }
      }
    }
  }

  .ant-tabs {
    .ant-tabs-nav-wrap {
      .ant-tabs-tab {
        padding: 8px 0;
      }
    }
  }
}
