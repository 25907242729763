//*********  DARK  *********//

.dark {
  background: #111;

  *:not(i):not(.ant-tag):not(.ant-rate):not(svg):not(path) {
    color: $d-text-principal-c !important;
    scrollbar-color: rgba($color: black, $alpha: 0.5) rgba($color: white, $alpha: 0.05);
    &::-webkit-scrollbar-thumb {
      background: rgba($color: white, $alpha: 0.4);
    }
  }
  .ant-tabs-bar {
    border-color: rgba($color: white, $alpha: 0.5);
  }

  .ant-btn-background-ghost {
    border-color: #666;
    color: #666 !important;
  }
  .ant-select-selection,
  .ant-input,
  .ant-input-number,
  .ant-input-number-handler-wrap {
    background-color: $d-input-bc !important;
    border-color: #666 !important;
  }
  .ant-input-group-addon {
    background-color: rgba($color: white, $alpha: 0.1);
    border-color: #666;
  }
  .ant-input-disabled {
    background-color: #252525 !important;
    border-color: #444 !important;
    color: whitesmoke;
  }
  .object-handler {
    .object-handler-content {
      .object-handler-container {
        .ant-layout {
          background-color: rgba($color: white, $alpha: 0.05);
        }
      }
    }
  }
  .ant-table-row-expand-icon {
    background-color: rgba($color: white, $alpha: 0.2);
  }

  .nb-users {
    background: rgba($color: white, $alpha: 0.05);
  }

  // DASHBOARDS
  .content-layout {
    .react-grid-item {
      .tile {
        background: rgba($color: black, $alpha: 0.3);
        .ant-layout {
          background: none;
        }
        &.sync-tile {
          border-color: rgba($color: $primary, $alpha: 0.5);
        }
      }
    }
  }

  .react-grid-layout {
    .react-grid-item {
      .tile-edit-icons {
        background: rgba($color: black, $alpha: 0.2);
        border: dotted 2px rgba($color: black, $alpha: 0.5);
        i {
          background: rgba($color: white, $alpha: 0.8);
        }
      }
      .react-resizable-handle {
        &:after {
          // Handler resize
          border-color: rgba($color: black, $alpha: 0.5);
        }
      }
    }
  }

  .sider-dashboard-settings {
    background-color: rgba($color: white, $alpha: 0.1);
    box-shadow: 0 0 20px rgba($color: black, $alpha: 0.3);
  }

  .management-content {
    background: rgba($color: white, $alpha: 0.03);
  }

  //Root Menu
  .settings-root-menu {
    .ant-menu-item {
      &:hover {
        background: rgba($color: black, $alpha: 0.5);
      }
    }
    .ant-menu-item-selected {
      background: none !important;
    }
  }

  .permission-modal {
    .ant-modal-body {
      .ant-tabs-bar {
        box-shadow: 0 10px 25px -15px rgba($color: black, $alpha: 0.8);
      }
      .ant-tabs-content {
        .ant-tabs-tabpane {
          .liste-line {
            background: rgba($color: white, $alpha: 0.02);
          }
        }
      }
    }
  }

  // FIXING THEME COLORS - Must be in the end of the sheet

  *[class$='primary']:not(.ant-btn),
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: $primary;
    border-color: $primary;
  }
  .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner,
  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
    &::after {
      background-color: $primary;
    }
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: rgba($color: white, $alpha: 0.6);
  }

  .tile-edit-icons i,
  .download-btn {
    background-color: $d-button-bc !important;
    border-color: #999;
    color: $d-text-principal-c;
    &:hover,
    &:focus {
      color: $primary;
      border-color: $primary !important;
      background-color: $d-button-bc-l-2 !important;
      .anticon,
      span {
        color: $primary !important;
        transition: 0.3s;
      }
    }
    &:active {
      border-color: $primary-d-10 !important;
      background-color: $d-button-bc-d-2 !important;
      .anticon,
      span {
        color: $primary-d-10 !important;
        transition: 0.1s;
      }
    }
  }
  ::selection {
    background-color: $primary !important;
    background: $primary !important;
  }
  .ant-card,
  .ant-select-dropdown,
  .ant-modal-content,
  .ant-dropdown-menu,
  .ant-popover-inner,
  .ant-popover-arrow,
  .ant-message-notice-content,
  .ant-drawer-content,
  .ant-calendar,
  .ant-calendar-input,
  .ant-table-filter-dropdown {
    background-color: $d-overlay-elem-bc !important;
  }
  .ant-popover-arrow {
    border-color: $d-overlay-elem-bc transparent transparent $d-overlay-elem-bc !important;
  }
  .ant-card-actions {
    background: none !important;
  }
  .ant-card-meta-description {
    color: #666;
    .anticon {
      color: #666;
    }
  }
  .ant-modal-header {
    background-color: inherit;
    border-color: rgba($color: white, $alpha: 0.3);
  }
  .ant-dropdown-menu-item {
    &:hover {
      background-color: rgba($color: white, $alpha: 0.1);
    }
  }
  .ant-select-dropdown-menu-item,
  .ant-tree-node-content-wrapper,
  .ant-select-tree-node-content-wrapper {
    &:hover {
      background-color: rgba($color: white, $alpha: 0.2) !important;
    }
  }
  .ant-select-dropdown-menu-item-selected,
  .ant-select-dropdown-menu-item-active,
  .ant-select-selection__choice {
    background-color: rgba($color: white, $alpha: 0.3) !important;
  }
  .ect-handler {
    background-color: rgba($color: white, $alpha: 0.1) !important;
  }
  .ant-collapse,
  .synoptic-form {
    background-color: rgba($color: white, $alpha: 0.05) !important;

    .ant-collapse-content {
      background: none;
    }
  }
  .serie-collapse {
    background: none !important;
    .serie-list-panel {
      background: rgba($color: white, $alpha: 0.1);
      .serie-param-collapse {
        background: none !important;
      }
    }
  }
  .ant-switch-checked {
    background-color: $primary !important;
  }
  .has-error .ant-form-explain span,
  .has-error .ant-form-split {
    color: tomato !important;
  }

  .ant-calendar-selected-start-date,
  .ant-calendar-selected-end-date,
  .ant-calendar-selected-date {
    .ant-calendar-date {
      background: $primary !important;
      border-color: $primary-d-10 !important;
    }
  }
  .ant-calendar-today {
    .ant-calendar-date {
      border-color: $primary;
    }
  }
  .ant-calendar-date {
    &:hover {
      background: $primary-l-10;
    }
  }

  .ant-calendar-range .ant-calendar-in-range-cell {
    &::before {
      background: $primary-l-15;
    }
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: $primary;
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: rgba($color: white, $alpha: 0.1);
  }

  // FIXING BOX SHADOW
  .ant-collapse-item.ant-collapse-item-active.serie-list-panel {
    & > .ant-collapse-header {
      box-shadow: 0 10px 18px -20px rgba($color: black, $alpha: 1) !important;
    }
  }
  .ant-card-hoverable:hover {
    box-shadow: 0 15px 13px -20px black;
  }

  // FIXING TABLE
  .ant-table {
    thead {
      th {
        background-color: rgba($color: black, $alpha: 0.2) !important;
      }
    }
    tbody {
      tr:hover {
        background-color: rgba($color: black, $alpha: 0.1) !important;
        td,
        td:hover {
          background: none !important;
        }
      }
      td:hover {
        background: none !important;
      }
    }
    .ant-table-placeholder {
      background: none;
    }
  }
  .ant-pagination {
    li,
    a {
      background: none !important;
    }
    .ant-pagination-item-active {
      border-color: $primary;
    }
  }

  // FIXING UPLOAD
  .ant-upload.ant-upload-drag {
    background-color: rgba($color: black, $alpha: 0.1) !important;
  }

  // FIXING TREE SELECTED
  .ant-tree li .ant-tree-node-content-wrapper {
    &:hover,
    &:active {
      background-color: rgba($color: black, $alpha: 0.4) !important;
    }
  }
  .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: $primary-d-15 !important;
  }

  .ant-radio-button-wrapper {
    background: $d-button-bc;
  }

  .add-dashboard-modal {
    .ant-radio-button-wrapper {
      background: none !important;
    }
  }
  .account-modal {
    .card-object {
      background: rgba($color: black, $alpha: 0.2) !important;
    }
  }

  // FIXING PHONE SELECT
  .react-phone-number-input__country-select {
    background-color: $d-overlay-elem-bc !important;
    color: white !important;
  }

  // FIXING MESSAGE ICON COLOR
  .ant-message-success {
    .anticon {
      color: #00ff00 !important;
    }
  }

  // FIXING ANTICON SVG COLOR
  .anticon {
    & > svg {
      color: inherit !important;
      & > path {
        color: inherit !important;
      }
    }
  }

  .ant-alert-error {
    background-color: tomato;
  }

  // FIXING BOOKMARK STAR
  .ant-rate {
    color: #fadb14 !important;
    .ant-rate-star.ant-rate-star-full {
      color: inherit !important;
      & > div {
        color: inherit !important;
        .ant-rate-star-first,
        .ant-rate-star-second {
          color: inherit !important;
          .anticon-star {
            color: inherit !important;
          }
        }
      }
    }
  }

  //Spiner
  .ant-spin-dot-item {
    background: $primary;
  }

  //Steps
  .ant-steps-item-finish {
    .ant-steps-item-icon {
      border-color: $primary;
      .ant-steps-icon {
        color: $primary !important;
      }
    }
  }
  .ant-steps-item-process {
    .ant-steps-item-icon {
      border-color: $primary;
      background: $primary;
    }
  }
  .ant-steps-item-wait {
    .ant-steps-item-icon {
      border-color: white;
    }
  }
  .ant-steps-item-custom {
    .ant-steps-item-icon {
      background: none;
    }
  }
  .ant-steps-item-process.ant-steps-item-custom {
    .ant-steps-item-icon {
      i {
        color: $primary;
      }
    }
  }
  .ant-steps-item-finish.ant-steps-item-custom {
    .ant-steps-item-icon {
      i {
        opacity: 0.4;
      }
    }
  }

  .ant-tooltip-arrow {
    border-color: rgba($color: black, $alpha: 0) rgba($color: black, $alpha: 0.75) rgba($color: black, $alpha: 0.75) rgba($color: black, $alpha: 0);
  }

  .settings-open {
    background-color: $d-overlay-elem-bc;
  }
}
