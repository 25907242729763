.dashboard {
  //max-height: calc(100vh - 100px);
  overflow: auto;
  flex: 1;

  .dashboard-header {
    top: 0;
    z-index: 100;
    padding: 10px 10px 0 10px;
    .dashboard-title {
      display: flex;
      .ant-avatar {
        margin-right: 10px;
        height: 50px;
        width: 50px;
        border-radius: 0;
      }
    }
  }

  .react-grid-layout {
    position: relative;
    
    @media screen and (max-width: 576px){
      .application-card-grid{
        width: 100%;
      }
    }
    @media screen and (min-width: 576px){
      .application-card-grid{
        width: 50%;
      }
    }
  }
}

.tile {
  height: 100%;

  .selectApplicationCenter{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: auto;

    .selectApplication{
      position: relative;
      display: block;
      margin: auto;
    }
  }
  
  &:hover {
    .actions, .zoom {
      opacity: 0.5;
    }
  }
  .actions, .zoom {
    z-index: 100;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba($color: grey, $alpha: 0.3);
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }

  .zoom {
    bottom: 0px;
    top: inherit;
  }
}

.settings-open-dashboard {
  transform: none !important;
}
