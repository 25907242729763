.roles__tabs {
  height: 100%;
  display: flex;
  .ant-tabs-nav {
    margin-bottom: 0;
    padding-left: 20px;
  }
  .ant-tabs-content-holder {
    display: flex;
  }
}

.nb-users {
  display: flex;

  height: 20px;
  min-width: 20px;
  justify-content: center;
  align-items: center;
}

.management-sider_add-modal {
  position: absolute;
  top: auto;
  left: 20px;
  bottom: 40px;
}

.roles-panel {
  height: 100%;
}
