// OBJECT TREE COMPONENT
.object-add-modal__button {
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  padding: 10px;
  svg {
    margin-top: 5px;
    margin-bottom: 10px;
    path {
      transition: 0.3s;
    }
  }
}

.object-add-form {
  display: flex;
  flex: 1;
  width: 100%;
}

.responsive-steps-container {
  position: sticky;
  top: 0;

  &.horizontal {
    padding: 10px 22px;
    width: 100%;
    border-bottom: 1px solid rgba($color: lightgrey, $alpha: 0.3);
    .responsive-steps {
      .ant-steps-item-active {
        display: inline-table;
      }
      .ant-steps-item {
        margin-right: 10px !important;
        flex: 1;
        .ant-steps-item-content {
          width: calc(100% - 25px);
          .ant-steps-item-title {
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            padding-right: 0;
          }
        }
      }
    }
  }
  &.vertical {
    padding: 20px 8px 22px 20px;
    border-right: 1px solid rgba($color: lightgrey, $alpha: 0.3);
    .responsive-steps {
      min-width: max-content;
      .ant-steps-item-icon {
        margin-right: 8px;
      }
    }
  }
}
.objet-add-body-container {
  overflow: auto;
  width: 100%;
  padding: 20px 22px;
}
