.user-drawer {
  .ant-drawer-content-wrapper {
    width: 500px !important;
    @media (max-width: 768px) {
      padding: 12px !important;
      width: 100% !important;
    }
  }
  .ant-drawer-header {
    background: transparent;
  }
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    .user-drawer__user-infos {
      padding: 20px 20px 0;
      display: flex;

      .user-infos__text {
        display: flex;
        flex: 1;
        justify-content: center;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 20px;
        .user-drawer__user-name {
          font-size: 2em;
          margin-bottom: 0px;
        }
        .user-infos__button {
          width: max-content;
          height: 28px;
          padding: 0 10px;
          margin-top: 10px;
        }
      }
    }
  }
}

.user-infos__image {
  text-transform: uppercase;
  font-weight: 800;
}

.user__password__change-modal {
  position: absolute;
  right: 30px;
  top: 70px;
  width: 440px !important;
  @media (max-width: 768px) {
    right: 0;
    left: 0;
    top: 140px;
    padding: 4px;
    width: 100% !important;
  }
}

.user-drawer__accessibility-select-container {
  padding: 0 20px;
  @media (max-width: 768px) {
    padding: 0 12px;
  }
}
.user-drawer__accessibility-select {
  width: 100%;
  display: flex;
  .language-select {
    margin-right: 20px;
  }

  .theme-select,
  .language-select {
    flex: 1 1;
    width: calc(50% - 10px);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    .theme-select,
    .language-select {
      min-width: 100%;
      margin-bottom: 10px;
    }
  }
}

.user-drawer__user-access-container {
  padding: 20px;
  @media (max-width: 768px) {
    padding: 0 12px;
  }
}
