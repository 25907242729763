.help-page-modal {
  padding-bottom: 0;
  .ant-modal-content {
    height: 100%;
    .ant-modal-header {
      padding-top: 16px;
      padding-bottom: 16px;
      .ant-modal-title {
        display: flex;
        align-items: center;
      }
    }
    .ant-modal-body {
      height: calc(100% - 55px);
      display: flex;
      flex-direction: column;
      .help-page-modal__sider {
        .ant-menu {
          overflow: hidden;
          &:hover {
            overflow-y: auto;
          }
        }
        .ant-menu-item-group-title {
          margin-top: 10px;
          &::after {
            content: '';
            position: relative;
            top: 12px;
            display: block;
            width: calc(100% - 10px);
            height: 1px;
            background: #f0f0f0;
          }
        }
      }
      .help-page-modal__content {
        p {
          max-width: 800px;
        }
      }
    }
  }
  .help-page-modal__search {
    width: 75%;
    max-width: 800px;
    margin-left: 40px;
  }
}
